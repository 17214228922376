.mainContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-right: 2vw;
}

.cardContainer {
  display: flex;
  width: auto;
  gap: 2vw;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10vh;
}

.filterTab {
  width: 20vw;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.detailsContainer {
  margin-bottom: 10vh;
}

.link {
  color: #041e41;
  font-family: "Open Sans", Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 5vh;
}

.link:hover {
  color: #133765;
}

.dateInput {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.dateInput:hover,
.dateInput:focus {
  border-color: #80bdff;
}

.dateFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 2px
}

@media (max-width:600px) {
  .mainContainer {
    padding: 0;
    margin: auto;
  }
  .detailsContainer {
    margin-left: auto;
    margin-right: auto;
  }
}