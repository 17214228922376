a {
    text-decoration: none;
}

.logo {
    width: 20%;
    height: 20%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    background-color: #041e41;
    color: #b29267;
    margin: 0;
    margin-bottom: 30px;
    padding: 5px;
}

.footer {
    margin-top: 20vh;
    height: 5rem;
    background-color: #041e41;
    padding: 3rem;
    color: #b29267;
    display: flex;
    flex-direction: column;
}

@media (max-width:600px) {
    .logo {
        width: 30%;
        height: 30%;
        padding: 0.5rem;
    }
}