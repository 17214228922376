.container {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.loginBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50%;
    margin-top: 10rem;
    transform: translate(-50%, -50%);
    background-color: #041e41dc;
    padding: 5vw;
    border: 5px solid rgb(209,173,115);
    border-radius: 5px;
}

.loginHeader {
    font-size: 3rem !important;
}

.loginButton {
    font-size: larger !important;
}