body {
    margin: 0;
    padding: 0;
}

.content {
    display: flex;
    color: #041e41;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 40%;
    padding-left: 10px;
    margin-left: 2rem;
    margin-bottom: 50px;
}

.buttonContainer {
    display: inline-flex;
    flex-direction: column;
    gap: 1vh
}

@media only screen and (max-width: 600px) {
    .formContainer {
        max-width: 100%;
        padding-left: 5vw;
        padding-right: 5vw;
        margin-left: auto;
        margin-right: auto;
    }
}

